<header id="header">
    <header id="header">
        <nav id="nav-menu" class="navbar navigation navbar-expand-lg navbar-light navigation fixed-top">
            <div class="container">
                <div id="logo" class="pull-left ml-5">
                    <a href="index" class="wow fadeInLeft" data-wow-delay="1s">
                        <svg xmlns="http://www.w3.org/2000/svg" width="78.2" height="46.47" viewBox="0 0 78.2 46.47">
              <g id="Groupe_244" data-name="Groupe 244" transform="translate(-362.195 -182.293)">
                <g id="Groupe_177" data-name="Groupe 177" transform="translate(362.195 182.293)">
                  <g id="Groupe_176" data-name="Groupe 176" transform="translate(0 0)">
                    <g id="Groupe_175" data-name="Groupe 175">
                      <path id="Tracé_414" data-name="Tracé 414" d="M937.668,600.178c-2.046,0-4.092.021-6.138-.008a2.827,2.827,0,0,1-2.555-2.331,5.085,5.085,0,0,1,.184-2.176q.995-4.788,2-9.572c.214-1.022.414-2.048.64-3.065a5.038,5.038,0,0,1,2.483-3.348,3.991,3.991,0,0,1,2.136-.58q5.707,0,11.414,0a2.9,2.9,0,0,1,2.56,1.318,3.111,3.111,0,0,1,.443,2.407c-.339,1.758-.718,3.5-1.082,5.254-.091.435-.322.661-.675.662q-3.259.006-6.517,0c-.365,0-.473-.16-.446-.617a14.961,14.961,0,0,1,.541-2.777c.287-1.145.611-2.275.8-3.455.113-.693.029-.822-.538-.822-.839,0-1.678,0-2.517,0-.486,0-.584.118-.711.719q-1.38,6.525-2.763,13.048c-.193.909-.389,1.817-.58,2.726-.108.515.006.708.429.71q1.328.008,2.655,0c.426,0,.569-.161.678-.674.447-2.111.9-4.22,1.344-6.332.094-.45.314-.657.667-.658q3.259,0,6.517,0c.406,0,.528.2.426.684-.355,1.693-.711,3.386-1.083,5.073a4.863,4.863,0,0,1-2.359,3.209,4.141,4.141,0,0,1-2.266.622c-1.9-.007-3.793,0-5.69,0Z" transform="translate(-906.37 -563.128)" fill="#082187"/>
                      <path id="Tracé_415" data-name="Tracé 415" d="M1478.387,579.08q4.018,0,8.036,0a3.01,3.01,0,0,1,2.176.793,3.115,3.115,0,0,1,.866,3.027c-.527,2.594-1.052,5.189-1.611,7.773a4.728,4.728,0,0,1-1.973,2.89,4.374,4.374,0,0,1-2.663.887q-3.4-.006-6.794,0c-.678,0-.78.09-.954.921-.287,1.372-.563,2.746-.843,4.12-.108.529-.258.685-.709.686q-3.225.006-6.449,0c-.473,0-.584-.171-.466-.744q.5-2.417,1.012-4.828.715-3.376,1.435-6.751c.426-2.013.836-4.033,1.281-6.039a1.482,1.482,0,0,0-.233-1.315c-.247-.324-.5-.635-.753-.955a.339.339,0,0,1-.1-.305c.041-.154.155-.156.258-.157.483,0,.966,0,1.448,0Zm2.006,1.969v0c-.46,0-.919-.006-1.379,0-.411.007-.553.156-.656.649q-1.053,5.037-2.108,10.074c-.122.583-.043.72.45.724.793.006,1.586,0,2.379,0,.7,0,.8-.1.98-.937q1.037-4.952,2.073-9.9c.092-.438.006-.592-.36-.6C1481.313,581.04,1480.853,581.049,1480.393,581.049Z" transform="translate(-1422.577 -563.109)" fill="#082187"/>
                      <path id="Tracé_416" data-name="Tracé 416" d="M373.624,578.721q4.018,0,8.036,0a3.08,3.08,0,0,1,1.978.622,3.131,3.131,0,0,1,1.05,3.289q-.765,3.832-1.592,7.643a4.823,4.823,0,0,1-2.381,3.189,4.247,4.247,0,0,1-2.3.627q-3.363-.006-6.726,0c-.719,0-.807.074-.994.968-.278,1.329-.546,2.663-.813,4-.118.586-.271.763-.735.764q-3.242.006-6.484,0c-.426,0-.54-.177-.431-.7q.806-3.866,1.625-7.727.912-4.324,1.824-8.648c.092-.434.154-.882.28-1.3a1.39,1.39,0,0,0-.236-1.311c-.187-.255-.4-.481-.586-.735-.112-.152-.327-.281-.258-.518s.3-.152.463-.153q3.984-.007,7.967,0Zm1.988,1.966v0c-.46,0-.92,0-1.379,0-.344,0-.543.182-.635.627q-1.068,5.145-2.16,10.282c-.085.4.107.52.342.524q1.431.026,2.862,0c.3-.006.5-.2.577-.612.155-.831.351-1.649.523-2.474q.807-3.864,1.61-7.729c.1-.464.026-.608-.361-.618C376.532,580.678,376.072,580.687,375.612,580.687Z" transform="translate(-362.195 -562.75)" fill="#082187"/>
                      <path id="Tracé_417" data-name="Tracé 417" d="M2067.682,188.114l-.776-.237a.588.588,0,0,1,.425-.413,4.089,4.089,0,0,0,.405-.109.334.334,0,0,0,.257-.359.314.314,0,0,0-.3-.311c-.149-.015-.3-.023-.448-.03a.61.61,0,0,1-.609-.4c.218-.449.666-.224.962-.516-.13-.09-.244-.024-.356-.028-.191-.006-.387-.03-.434-.324a.625.625,0,0,1,.245-.589c.152-.141.458.011.468-.387.006-.252-.027-.506-.045-.758-.039-.521-.118-1.059.372-1.359a5.951,5.951,0,0,1,.094,1.684c.009.372-.058.757.375.808.129.015.245.181.242.388,0,.222-.137.286-.277.335a.656.656,0,0,0-.216.132,2.54,2.54,0,0,1,.992.255c.107.043.234.06.256.25a.384.384,0,0,1-.152.369,1.348,1.348,0,0,0-.525,1.257.206.206,0,0,1-.02.128c-.373.335-.132.765-.117,1.166.05,1.341.111,2.682.125,4.024.038,3.631.237,7.245.617,10.843.028.261.1.453.31.527a.892.892,0,0,1,.217.129c.245.179.316.376.013.571-.173.111-.2.283-.2.512a18.063,18.063,0,0,0,.486,3.422,30.044,30.044,0,0,0,1.854,6.236c.6,1.372,1.313,2.659,1.964,3.993.664,1.36,1.314,2.731,1.969,4.1.054.114.109.229.153.35.134.36.067.548-.233.671-.549.225-1,.015-1.379-.662q-1.14-2.052-2.1-4.257c-.338-.779-.668-1.565-.977-2.364-.2-.511-.484-.743-.928-.746-1.551-.01-3.1-.047-4.653-.074a1.014,1.014,0,0,1-.207-.013q-.515-.117-.229-.672a.948.948,0,0,1,.959-.618c1.308.09,2.617.155,3.922.292.347.036.435-.111.3-.517a57.21,57.21,0,0,1-1.722-7.2,34.184,34.184,0,0,1-.49-4.786q-.242-5.566-.491-11.132c-.02-.431-.08-.859-.13-1.287a1.117,1.117,0,0,0-.343-.749.979.979,0,0,1-.35-1.056C2067.079,188.106,2067.413,188.213,2067.682,188.114Z" transform="translate(-1997.879 -182.293)" fill="#082187"/>
                      <path id="Tracé_418" data-name="Tracé 418" d="M1671.179,1180.747c.663.332.752,1,.725,1.736-.014.374.123.855-.056,1.094s-.565.073-.859.077c-.773.011-.773,0-.773-.957,0-.16,0-.319,0-.479a.475.475,0,0,0-.381-.522c-.023,0-.046,0-.068-.008-.857-.137-.857-.137-.857.928,0,1.042,0,1.042-.81,1.042-.838,0-.838,0-.838-1.054,0-1.858.005-3.715-.005-5.573,0-.311.049-.436.321-.423.929.045,1.861-.055,2.79.062a3.214,3.214,0,0,1,.641.153,1.237,1.237,0,0,1,.8.98,5.221,5.221,0,0,1,.04,1.938A1.473,1.473,0,0,1,1671.179,1180.747Zm-2.273-1.624c0,.87,0,.871.707.825.092-.006.183-.015.274-.028a.365.365,0,0,0,.289-.271c.258-.708-.023-1.287-.631-1.3C1668.907,1178.328,1668.907,1178.328,1668.907,1179.123Z" transform="translate(-1615.789 -1137.275)" fill="#dd151b"/>
                      <path id="Tracé_419" data-name="Tracé 419" d="M2097.9,1183.222a4,4,0,0,1-1.572-.172,1.832,1.832,0,0,1-.883-2.052c.039-.162.126-.143.213-.144q.431-.005.862,0c.246,0,.538-.112.562.412.006.142.147.2.267.233a2.529,2.529,0,0,0,.754.047c.284-.016.473-.09.468-.55s-.21-.522-.481-.556c-.468-.058-.939-.1-1.4-.184-.811-.149-1.183-.7-1.206-1.731,0-.131,0-.261,0-.392,0-1.318.433-1.953,1.471-2.014a5.149,5.149,0,0,1,2.191.136,1.744,1.744,0,0,1,.921,1.977c-.028.158-.1.174-.194.174-.391,0-.781,0-1.172,0-.133,0-.219-.034-.226-.232-.01-.3-.168-.385-.377-.383-.184,0-.367,0-.55,0-.228,0-.4.054-.4.429-.005.355.1.484.372.512.492.051.982.131,1.47.222.869.163,1.243.7,1.264,1.808,0,.217,0,.436-.015.652-.076,1.1-.632,1.766-1.507,1.8C2098.454,1183.232,2098.178,1183.222,2097.9,1183.222Z" transform="translate(-2026.911 -1136.757)" fill="#dd151b"/>
                      <path id="Tracé_420" data-name="Tracé 420" d="M1954.367,1183.072a4.417,4.417,0,0,1-1.438-.132,1.76,1.76,0,0,1-1.008-2.063c.028-.161.1-.174.194-.174.4,0,.8,0,1.206,0,.131,0,.18.062.189.226a.415.415,0,0,0,.365.434,2.651,2.651,0,0,0,.686.037c.271-.016.485-.053.488-.524s-.2-.561-.493-.584a7.393,7.393,0,0,1-1.6-.242,1.407,1.407,0,0,1-.978-1.472,4.724,4.724,0,0,1,.075-1.465c.156-.568.45-.973.918-1.058a5.275,5.275,0,0,1,2.7.071,1.744,1.744,0,0,1,.874,1.973c-.033.161-.112.159-.2.159-.391,0-.781,0-1.172,0-.144,0-.206-.065-.213-.25-.011-.275-.161-.362-.355-.365s-.367-.007-.55,0c-.213.006-.409.011-.422.4-.012.373.092.513.384.542.491.048.982.13,1.469.222.848.16,1.227.706,1.252,1.777.005.217,0,.436-.011.652-.074,1.139-.645,1.811-1.558,1.834C1954.9,1183.078,1954.632,1183.072,1954.367,1183.072Z" transform="translate(-1888.903 -1136.607)" fill="#dd151b"/>
                      <path id="Tracé_421" data-name="Tracé 421" d="M1816.363,1180.12c0-1.03.008-2.06,0-3.09,0-.306.041-.434.316-.43,1.241.019,2.482.013,3.722,0,.212,0,.318.051.3.356a9.48,9.48,0,0,0,0,1.044c.014.271-.06.346-.271.341-.7-.016-1.4,0-2.1-.012-.23-.006-.351.057-.311.377a1.1,1.1,0,0,1,0,.261c-.038.313.042.429.313.418.677-.027,1.355,0,2.033-.014.208,0,.29.065.272.34a7.227,7.227,0,0,0,0,.87c.016.273-.069.346-.276.342-.643-.014-1.287.007-1.93-.011-.278-.008-.44.02-.424.473.014.386.047.563.406.541.688-.042,1.378,0,2.068-.018.211,0,.316.046.3.352-.021.361-.012.725,0,1.088.006.212-.031.309-.223.307q-1.982-.014-3.964,0c-.237,0-.221-.163-.221-.36Q1816.365,1181.709,1816.363,1180.12Z" transform="translate(-1759.02 -1137.269)" fill="#dd151b"/>
                      <path id="Tracé_422" data-name="Tracé 422" d="M1224.412,1180.11c0-1.03.006-2.061,0-3.091,0-.281.035-.411.295-.407q1.878.025,3.757,0c.238,0,.3.107.284.38-.025.432.138.989-.059,1.264s-.649.077-.989.089c-.459.017-.92.019-1.379,0-.22-.01-.266.086-.266.346,0,.7-.007.7.563.7.6,0,1.2.01,1.792-.005.2-.005.247.082.252.322.027,1.224.032,1.224-.92,1.224-.414,0-.827,0-1.241,0-.513.008-.417-.079-.448.547-.018.361.066.468.356.456.689-.028,1.379.012,2.068-.018.289-.012.355.117.336.445s-.013.668,0,1c.008.223-.049.3-.233.3-1.31-.008-2.62-.01-3.93,0-.216,0-.238-.117-.237-.338C1224.416,1182.258,1224.412,1181.184,1224.412,1180.11Z" transform="translate(-1190.411 -1137.28)" fill="#dd151b"/>
                      <path id="Tracé_423" data-name="Tracé 423" d="M1361.012,1183.621c-.615,0-1.154-.01-1.693.006-.177,0-.231-.107-.291-.281-.236-.687-.483-1.369-.759-2.144-.266.711-.523,1.331-.728,1.978-.114.358-.266.472-.562.451-.469-.033-.941-.009-1.476-.009.254-.626.487-1.21.725-1.79.211-.515.417-1.033.644-1.536a.75.75,0,0,0,.011-.647c-.393-1-.771-2-1.181-3.069.607,0,1.163,0,1.719,0,.169,0,.2.169.245.311.157.466.309.935.466,1.4.043.129.058.283.192.379.192-.579.389-1.148.57-1.725.081-.257.184-.389.427-.375.5.027,1.005.008,1.566.008-.267.686-.513,1.319-.76,1.951a1.475,1.475,0,0,1-.114.267,2.353,2.353,0,0,0,.057,2.517C1360.416,1182.023,1360.68,1182.8,1361.012,1183.621Z" transform="translate(-1316.32 -1137.237)" fill="#dd151b"/>
                      <path id="Tracé_424" data-name="Tracé 424" d="M1519.582,1180.148c0-1.044.009-2.087-.005-3.131,0-.312.077-.391.314-.386.849.016,1.7.006,2.548.007a2.487,2.487,0,0,1,.947.2,1.5,1.5,0,0,1,.888,1.116,5.923,5.923,0,0,1,.091,2.109c-.135,1.162-.606,1.7-1.533,1.8a8.23,8.23,0,0,1-1.272.035c-.284-.015-.356.116-.336.447s-.007.667.008,1c.011.256-.041.364-.264.35a8.481,8.481,0,0,0-1.1,0c-.231.015-.292-.084-.289-.366C1519.589,1182.265,1519.582,1181.207,1519.582,1180.148Zm1.646-.922c0,.963,0,.963.748.953.675-.009.75-.1.744-.957,0-.116,0-.232-.014-.347a.444.444,0,0,0-.321-.449,2.336,2.336,0,0,0-.478-.056C1521.227,1178.36,1521.227,1178.363,1521.228,1179.226Z" transform="translate(-1473.933 -1137.298)" fill="#dd151b"/>
                      <path id="Tracé_425" data-name="Tracé 425" d="M2039,572.487a1.176,1.176,0,0,1,.13.738c-.029,2-.043,4-.166,6a32.8,32.8,0,0,1-1.283,7.97,23.929,23.929,0,0,1-1.29,2.965.6.6,0,0,1-.4.314c-.391.134-.563-.071-.431-.559.344-1.273.707-2.538,1.068-3.8a49.349,49.349,0,0,0,1.749-7.8c.2-1.691.347-3.391.521-5.086C2038.926,572.978,2038.966,572.733,2039,572.487Z" transform="translate(-1969.327 -556.865)" fill="#082187"/>
                      <path id="Tracé_426" data-name="Tracé 426" d="M2076.287,1107.981c2.167.005,4.239,1.867,4.945,4.454.015.055.024.112.039.166.069.247.245.51-.067.7a.491.491,0,0,1-.715-.287c-.089-.2-.141-.425-.216-.636-1.019-2.88-3.322-4.3-5.751-3.569-.158.048-.416.26-.477-.022s.253-.241.4-.315a4.749,4.749,0,0,1,1.326-.44C2075.943,1108,2076.116,1108,2076.287,1107.981Z" transform="translate(-2006.369 -1071.281)" fill="#082187"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
                    </a>
                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
                <div class="menu-center collapse navbar-collapse wow fadeInRight" id="navbarNav" data-wow-delay="0.5s">
                    <ul class="navbar-nav ml-auto nav-menu topnav">
                        <li class="nav-item menu-active active accueil">
                            <a class="nav-link scroll-link" href="#top-content">Accueil</a>
                        </li>
                        <li class="nav-item services">
                            <a class="nav-link scroll-link" href="#more-services">Nos services</a>
                        </li>
                        <li class="nav-item devis">
                            <a class="nav-link scroll-link" href="#call-to-action">Obtenez un devis</a>
                        </li>
                        <li class="nav-item agences">
                            <a class="nav-link scroll-link" href="#portfolio">Nos agences</a>
                        </li>
                        <li class="nav-item about">
                            <a class="nav-link scroll-link" href="#testimonials">Qui sommes nous ?</a>
                        </li>
                        <li class="nav-item contact-us">
                            <a class="nav-link scroll-link" href="#contact-us">Nous contacter</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll-link mr-1" href="#_blank"><img src="assets/img/icons/icon-fb.png" class="" alt="..." style="width: 15px;"></a>
                            <a class="nav-link scroll-link" href="#_blank"><img src="assets/img/icons/icon-insta.png" class="" alt="..." style="width: 15px;"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>

    <!-- Top menu -->

    <!-- Top content -->
    <section id="top-content">
        <div class=" top-content">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <!--
<ol class="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active boutton "></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1" class="boutton"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2" class="boutton"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="3" class="boutton"></li>

</ol>
-->
                <div class="carousel-inner">
                    <div *ngFor="let item of listSlide,let i=index" class="carousel-item" [ngClass]="{'active': i==0}" data-interval="10000">
                        <div class="carousel-background"><img src="{{item.url}}" class="d-block w-100" alt="..." style="height: 100%;"></div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <!--<span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
                    <img src="assets/img/arrow-left.png" class="" alt="..." style="width:49px;height:103px">

                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <!--<span class="carousel-control-next-icon" aria-hidden="true"></span>-->
                    <img src="assets/img/arrow-right.png" class="" alt="..." style="width:49px;height:103px">
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </section>
    <!-- More services -->
    <section id="more-services">
        <div class="more-services-container section-container pt-5">
            <div class="container">
                <div class="d-flex justify-content-center">
                    <form class="col-md-7 col-12">
                        <h4 class="wow slideInLeft" style="font-weight: 500;">Suivre un colis : </h4>
                        <div class="form-group">
                            <label for="exampleFormControlInput1" style="font-weight: 400;">*Saisissez la référence du colis</label>
                            <input type="text" [(ngModel)]="ref" [ngModelOptions]="{standalone: true}" class="form-control" id="exampleFormControlInput1" placeholder="973B374H">
                        </div>
                        <button type="submit" class="btn" (click)="checkColis()">
              Suivre
              <div *ngIf="checking" class="spinner-border spinner-border-sm" style="margin-bottom: 4px" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
                    </form>
                </div>
                <div class="lead service-text text-center mt-5">
                    <p>Expediez vos colis dans la sous région, <br> vers l'Europe, l'Amerique et l'Asie</p>
                </div>
                <div class="service-content mt-5">
                    <h4 class="text-center pt-5 wow slideInLeft" style="color: #082187">Nos <span style="border-bottom: 4px solid #DD151B;">services</span></h4>
                    <div class="container-fluid mt-5">
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideServiceConfig">
                            <div *ngFor="let item of listServices" ngxSlickItem class="slide col-sm-12 col-md-6 col-lg-3 wow slideInLeft service-one">
                                <div class="card-service ">
                                    <div class="image-content shadow-sm">
                                        <img src="{{item.logo}}" class="rounded mx-auto d-block" alt="..." style="width: 40px;">
                                        <h5 class="card-title text-center mt-3">{{item.nom}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <p style="font-size: 11px;">
                                            {{item.detail}}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </ngx-slick-carousel>

                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- About us Suivez-Colis -->

    <!-- Call to action -->
    <section id="call-to-action">
        <div id="obtenir-devis" class="call-to-action-container section-container section-container-image-bg py-5">
            <div class="container">
                <div class="col-12 row d-flex justify-content-center">
                    <form [formGroup]="devisForm" (ngSubmit)="sendDevis()">
                        <h4 class="title-devis text-center mb-5 mt-5 wow slideInLeft">Obtenez un <span style="border-bottom: 4px solid #FDE900;">dévis</span></h4>
                        <div class="form-row wow bounceInLeft">
                            <div class="form-group col-md-12 col-lg-6">
                                <input type="text" class="form-control" placeholder="Nom*" formControlName="lastname">
                            </div>
                            <div class="form-group col-md-12 col-lg-6">
                                <input type="text" class="form-control" placeholder="Prénoms*" formControlName="firstname">
                            </div>

                        </div>
                        <div class="form-row wow bounceInRight">
                            <div class="form-group col-md-12 col-lg-6">
                                <input type="number" class="form-control" formControlName="weight" placeholder="Poid du colis (Kg)*">
                            </div>
                            <div class="form-group col-md-12 col-lg-6">
                                <select class="form-control" formControlName="begin" style="color: #736f6f;border: 1.5px solid yellow;">
                  <option value="-1" selected>Pays de départ*</option>
                  <option *ngFor="let item of listPays" value="{{item.nom}}">{{item.nom}}</option>
                </select>
                            </div>
                        </div>
                        <div class="form-row wow bounceInLeft">
                            <div class="form-group col-md-12 col-lg-6">
                                <input type="email" class="form-control" formControlName="email" email="true" placeholder="Adresse mail">
                            </div>
                            <div class="form-group col-md-12 col-lg-6">
                                <select class="form-control" formControlName="arrived" style="color: #736f6f;border: 1.5px solid yellow;">
                  <option value="-1" selected>Pays d'arrivé*</option>
                  <option *ngFor="let item of listPays" value="{{item.nom}}">{{item.nom}}</option>

                </select>
                            </div>
                        </div>
                        <div class="form-row wow bounceInUp">
                            <div class="form-group col-md-12 col-lg-6">
                                <input type="tel" class="form-control" id="phone" formControlName="phone" placeholder="Télephone*">
                            </div>
                            <div class="col-md-12 col-lg-2"></div>
                            <div class="col-md-12 col-lg-4">
                                <button type="submit" [disabled]="!devisForm.valid" class="btn">
                  Obtenir le Devis
                  <div class="spinner-border spinner-border-sm ml-2" *ngIf="sendingDevis" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>


    <!-- Portfolio -->
    <section id="portfolio">
        <div class="portfolio-container section-container mt-5">
            <div id="agence-container" class="container">
                <h5 class="text-center wow slideInLeft" style="color:#082187">Nos <span style="border-bottom: 4px solid #DD151B;">agences</span></h5>
                <div class="row justify-content-center mt-5">
                    <div *ngFor="let item of listAgences" class="col-md-6 col-lg-4 wow swing" data-wow-delay="1s">
                        <div class="card-agence w-100 shadow-sm">
                            <div class="container-img py-3">
                                <img src="{{item.logo}}" alt="" width="100" class="rounded mx-auto d-block ">
                            </div>
                            <div class="card-body bg-white">
                                <h4 class="card-title text-center">{{item.nom}}</h4>
                                <div class="adress-info mt-5">
                                    <img src="assets/img/agence/map.png" alt="" width="25" class="rounded mx-auto d-block ">
                                    <p class="text-center mt-3">{{item.adresses}}</p>
                                </div>
                                <p class="phone-info mt-5">
                                    <img src="assets/img/agence/phone.png" alt="" width="25" class="float-left">
                                    <span class="ml-4">{{item.tel}}</span>

                                </p>
                                <p class="mail- mt-4">
                                    <img src="assets/img/agence/mail.png" alt="" width="25" class="float-left">
                                    <a href="mailto:{{item.mail}}"><span class="ml-4">{{item.email}}</span></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Testimonials -->
    <section id="testimonials">
        <div class="testimonials-container section-container section-container-image-bg">
            <div id="presentation">
                <br>
                <h5 class="text-center wow slideInLeft pt-5">
                    <span class="mt-3">Qui sommes <span style="border-bottom: 4px solid #FDE900;">nous?</span></span>
                </h5>
                <div class="row">
                    <div class="col-md-5"></div>
                    <div class="col-sm-12 col-md-12 col-lg-7 text-content">
                        <p class="text-justify">
                            <span class="para-one">
                              Agence de transit dont la mission consiste à faciliter le transport de colis tout en alliant rapidité et sécurité grâce à un système innovant et adapté aux besoins de la clientèle.
                          </span>
                            <span id="demo"></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Blog -->
    <section id="blog">
        <div class="blog-container section-container mt-5" style="margin-bottom: 150px;">
            <div class="section-global" style="padding-bottom: 100px;">
                <div class="container section-partenaire">
                    <h4 class="text-center mb-5" style="color: #2E3192;">Ils nous font confiance</h4>
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                        <div *ngFor="let partenaire of listPartenaire" ngxSlickItem class="slide">
                            <img [src]="partenaire.logo" id="imgPreview1" class="card-img-top slider-partner" alt="...">
                        </div>
                    </ngx-slick-carousel>
                </div>
                <div class="section-temoignage">
                    <h4 class="text-center mb-5">Témoignage &nbsp;<span class="red-videos">vidéos</span></h4>

                    <ngx-slick-carousel class="carousel slider_videos" #slickModal="slick-carousel" [config]="slideVideosConfig">

                        <div *ngFor="let item of listTemoignage" ngxSlickItem class="slide col-sm-12  col-lg-4 wow ">

                            <div ngxSlickItem class="slide">
                                <div class="video-wrapper">
                                    <iframe width="766" height="383" [src]="item.lien" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <p class="temoignage-title">{{item.titre}}</p>
                                <span>{{item.detail}}</span>
                            </div>

                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div id="contact-us" class="contact-us-container container pt-5">
                <div class="header-title wow slideInLeft">
                    <h6 class="text-center title-contact">Nous <span style="border-bottom: 4px solid #DD151B;">Contacter</span>
                    </h6>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12 col-lg-4">
                        <form [formGroup]="contactForm" (ngSubmit)="sendEmail()">
                            <div class="form-group wow bounceInLeft">
                                <input type="text" class="form-control" formControlName="nom" placeholder="Nom">
                            </div>
                            <div class="form-group wow bounceInRight">
                                <input type="text" class="form-control" placeholder="Prenom" formControlName="prenoms">
                            </div>
                            <div class="form-group col-12" data-aos="fade-left">
                                <div class="row">
                                    <select id="selectIndicator" class="col-3 form-control" formControlName="indicator">
                    <option value="+225" selected>+225</option>
                    <option value="+33">+33</option>
                  </select>
                                    <div class="col-9 p-0 pl-1">
                                        <input type="text" class="form-control" formControlName="tel" placeholder="Contact">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group wow bounceInLeft">
                                <input type="email" class="form-control" formControlName="email" email="true" placeholder="adresse@mail">
                            </div>

                            <div class="form-row wow bounceInRight">
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message" formControlName="message"></textarea>
                            </div>
                            <button type="submit" name="btn_sms" [disabled]="!contactForm.valid" id="btn_sms" class="mt-5 wow bounceInUp">
                Envoyer
                <div class="spinner-border spinner-border-sm ml-2" *ngIf="loadingEmail"  role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
                        </form>
                    </div>
                    <div class="col-md-0 col-lg-2 mit"></div>
                    <div class="col-md-6 col-lg-3 mit wow bounceInDown">
                        <div class="card-contact-one w-100 shadow-sm">
                            <div class="container-title py-2">
                                <h5 class="card-title text-center text-white">Nos contacts</h5>
                            </div>
                            <div class="card-body">
                                <div class="adress-info mt-1">
                                    <img src="assets/img/agence/map.png" alt="" width="25" class="rounded mx-auto d-block ">
                                    <p class="text-center mt-3">{{parameters.adresse}}</p>
                                </div>
                                <p class="phone-info mt-2">
                                    <img src="assets/img/agence/phone.png" alt="" width="25" class="float-left">
                                    <span class="ml-4">{{parameters.tel}}</span>

                                </p>
                                <p class="mail- mt-4">
                                    <img src="assets/img/agence/mail.png" alt="" width="25" class="float-left">
                                    <span class="ml-4 ">{{parameters.email}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 mit wow bounceInUp">
                        <div class="card-contact-two w-100 shadow-sm">
                            <div class="container-title py-2">
                                <h5 class="card-title text-center text-dark">Notre disponibilité</h5>
                            </div>
                            <div class="card-body">
                                <div class="horaire mt-5">
                                    <span class="title ml-5 text" style="font-weight:bold">Lundi - Vendredi</span> <br>
                                    <img src="assets/img/contact/time.png" alt="" width="10" class="ml-5">
                                    <span class="ml-2">09:00 - 19:00</span>
                                </div>
                                <div class="horaire mt-3">
                                    <span class="title ml-5 text" style="font-weight:bold">Samedi</span> <br>
                                    <img src="assets/img/contact/time.png" alt="" width="10" class="ml-5">
                                    <span class="ml-2">09:00 - 15:00</span>
                                </div>
                                <div class="horaire mt-3">
                                    <span class="title ml-5 text" style="font-weight:bold">Diamanche</span> <br>
                                    <img src="assets/img/contact/time.png" alt="" width="10" class="ml-5">
                                    <span class="ml-2">09:00 - 19:00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer -->
    <footer>
        <div class="container pt-1">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-12">
                    <nav id="nav-footer" class="navbar naviguer  navbar-light">
                        <p>
                            <span><a class="nav-link" href="#top-content">Accueil </a></span> &nbsp;&nbsp;
                            <span><a class="nav-link" href="#more-services">Nos services </a></span>
                            <span><a class="nav-link" href="#call-to-action">Obtenez un dévis</a></span>
                            <span><a class="nav-link" href="#portfolio">Nos agences</a></span>
                            <span><a class="nav-link" href="#testimonials">Qui sommes nous </a></span>
                            <span><a class="nav-link" href="#blog">Nous contacter </a></span>
                        </p>
                    </nav>
                </div>
            </div>
            <div class="icons-block">
                <div class="content-logo">
                    <a href="#" class=" wow fadeIn" data-wow-delay="1s"><img src="assets/img/footer-logo.png" alt="" class="logo-white"></a>
                </div>
                <div class="networks wow fadeIn" data-wow-delay="1s">
                    <a href="#" class="twitter "><img src="assets/img/footer/facebook.png" alt="" width="30"></a>
                    <a href="#" class="facebook "><img src="assets/img/footer/google.png" alt="" width="30"></a>
                    <a href="#" class="facebook"><img src="assets/img/footer/twitter.png" alt="" width="30"></a>
                    <a href="#" class="facebook"><img src="assets/img/footer/instagram.png" alt="" width="30"></a>
                </div>
            </div>
            <div class="copyright d-flex justify-content-center mt-4">
                <p>
                    <a href="../logSmart.php">&copy;</a>
                    <span> {{currentYear}} concu par NOVATE DIGITAL.Tous les droits reservés</span>
                </p>

            </div>

        </div>
    </footer>
    <!-- Modal -->
    <div class="modal fade" id="coliModal" tabindex="-1" aria-labelledby="modalTitle" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitle">STATUT DE VOTRE COLIS {{colis.reference}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    {{colis.message}}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
    <a class="back-to-top" style="display: inline;"><i class='fa fa-chevron-up' aria-hidden='true'></i></a>