import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from "ngx-toastr";
import {Appconstant} from "../constant/Appconstant";
import {FormGroup, FormControl, Validator, Validators} from '@angular/forms';


declare var $: any;

console.log = function() {}

///const BASE_URL="http://127.0.0.1:8000/api/";
//const BASE_URL_SIMPLE = "http://127.0.0.1:8000";

const BASE_URL = "https://api.pariscenterplus.com/";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/ld+json; charset=utf-8 ',
  })
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = "paris-center";
  listSlide: Array<any> = [];
  listServices: Array<any> = [];
  listAgences: Array<any> = [];
  listPays: Array<any> = [];
  listTemoignage: Array<any> = [];
  listPartenaire: Array<any> = [];
  parameters:any = {adresse:"",tel:"",email:""};
  colis: Colis = {
    reference: ""
  };

  indicator: string ;
  loadingEmail: boolean = false;

  currentYear = new Date().getFullYear()

  sendingDevis: boolean = false;
  sendingContact: boolean = false;


  contactForm : FormGroup;
  devisForm: FormGroup;

  ref:string;
  checking: boolean = false;
  paysFrom: any;
  paysTo: any;
  slideConfig = {
    "slidesToShow": 7,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide left_arrow'></div>",
    "prevArrow": "<div class='nav-btn prev-slide right_arrow'></div>",
   // "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  slideVideosConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide left_arrow' style='position:relative'><i class='fa fa-chevron-right'  style='font-size:50px' aria-hidden='true'></i></div>",
    "prevArrow": "<div class='nav-btn prev-slide right_arrow' style='position:relative'><i class='fa fa-chevron-left' style='font-size:50px' aria-hidden='true'></i></div>",
   // "dots": true,
    "infinite": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  slideServiceConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "prevArrow": "<div class='nav-btn next-slide service-left_arrow' style='position:relative;top:45px'><img src='assets/img/next-service.png' style='height:90px' ></div>", //next-action
    "nextArrow": "<div class='nav-btn prev-slide service-right_arrow' style='position:relative;top:-285px'><img src='assets/img/prev-service.png' style='height:90px'></div>", //prev-action
    // "dots": true,
    "infinite": true,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]

  };

  constructor(private wowService: NgwWowService,private http: HttpClient, private sanitizer: DomSanitizer,
              private toastr: ToastrService) {
    this.wowService.init();
    this.contactForm = new FormGroup({
      nom: new FormControl('',Validators.required),
      prenoms: new FormControl('',Validators.required),
      tel: new FormControl('',Validators.required),
      email: new FormControl('',Validators.required),
      message: new FormControl('',Validators.required),
      indicator: new FormControl('',Validators.required)
    });
    this.devisForm = new FormGroup({
      firstname: new FormControl('',Validators.required),
      lastname: new FormControl('',Validators.required),
      phone: new FormControl('',Validators.required),
      email: new FormControl('',Validators.required),
      begin: new FormControl('-1',Validators.required),
      arrived: new FormControl('-1',Validators.required),
      weight: new FormControl('',Validators.required),
    })
  }


  ngOnInit(): void {
    this.getSlide();
    this.getServices();
    this.getAgences();
    this.getPays();
    this.getTemoignage();
    this.getParametre();
    this.getPartenaires();
  }

  sendDevis(){
    if(this.devisForm.get('begin').value.toString() === '-1'){
      this.toastr.error("Veuillez choisir le pays de départ svp","Erreur")
      return
    }

    this.sendingDevis = true
    console.log(this.devisForm.value)
    this.http.post(BASE_URL + "devis",this.devisForm.value).subscribe(
      reponse => {
        this.sendingDevis = false
        this.devisForm.reset()
        this.toastr.success("Dévis transmis avec succès. Nos agent vous contacterons sous peu. Merci","Succès");
      }, error => {
        this.sendingDevis = false;
        this.toastr.error("Une erreur c'est produite, veuillez réessayer plus tard","Erreur");
      }
    )
  }

  getSlide(){
    this.http.get(BASE_URL + "bannieres").subscribe(
      response => {
        console.log(response);
        this.listSlide = response["hydra:member"];
      },
      error => {
        console.log(error);
      }
    )
  }

  getPartenaires(){
    this.http.get(BASE_URL + "partenaires").subscribe(
      response => {
        console.log(response);
        this.listPartenaire = response["hydra:member"];
      },
      error => {
        console.log(error);
      }
    )
  }

  getServices(){
    this.http.get(BASE_URL+"services").subscribe(
        response => {
          this.listServices = response['hydra:member'];
        },
        error => {
          console.log(error);
        }
    )
  }

  getAgences(){
    this.http.get(BASE_URL+"agences").subscribe(
      response => {
        this.listAgences = response["hydra:member"];
      },
      error => {
        console.log(error);
      }
    );
  }
  getPays(){
    this.http.get(BASE_URL+"pays").subscribe(
      response => {
        this.listPays = response["hydra:member"];
      },
      error => {
        console.log(error);
      }
    )
  }

  checkColis(){
    console.log(this.ref);
    if(this.ref == undefined || this.ref == ""){
      this.toastr.error("Veuillez saisir une référence Colis SVP","Erreur");
      return;
    }
    this.checking = true;
    this.http.get(BASE_URL + "colis/check/"+this.ref).subscribe(
      (response: Colis) => {
        this.checking = false;
        this.colis = response;
        if(response.statut == Appconstant.STATUS_ENTREPO){
          this.colis.message = "Votre colis est dans nos entrepot prêt à être expédié"
        }else if(response.statut == Appconstant.STATUS_MOUVEMENT){
          this.colis.message = "Votre colis est en cours de déplacement"
        }
        else if(response.statut == Appconstant.STATUS_TRANSIT){
          this.colis.message = "Votre colis est en cours de transit"
        }
        else if(response.statut == Appconstant.STATUS_DESTINATION){
          this.colis.message = "Votre colis est arrivé dans le pays de destination"
        }
        else if(response.statut == Appconstant.STATUS_RECEPTION){
          this.colis.message = "Votre colis à été rétiré"
        }
        $('#coliModal').modal();
        this.ref = "";
      },error => {
        this.checking = false;
        this.toastr.error(error.error['hydra:description'],"Erreur");
      }
    )
  }

  getParametre(){
    this.http.get(BASE_URL+"settings").subscribe(
      response => {
        this.parameters = response["hydra:member"][0];
      },
      error => {
        console.log(error);
      }
    )
  }

  getTemoignage(){
    this.http.get(BASE_URL+"temoignages").subscribe(
      response => {
        let list = response["hydra:member"];
        list.forEach(it=>{
          this.listTemoignage.push({
            detail: it.detail,
            lien: this.trustUrl(it.lien),
            titre: it.titre
          })

        });
        console.log(this.listTemoignage);
      },
      error => {
        console.log(error);
      }
    )
  }

  sendEmail(){
    this.loadingEmail = true;
    let data = {...this.contactForm.value};
    data.contact = data.indicator+data.contact;
    this.http.post(BASE_URL + "sendmail",data).subscribe(
      response => {
        this.loadingEmail = false;
        if(response['status'] === 200){
          this.toastr.success("Message transmis avec succès","Succès");
          this.contactForm.reset();
        } else {
          this.toastr.error(response['message'],"Erreur");
        }
      }, error => {
        this.loadingEmail = false;
        this.toastr.error("Une erreur c'est produite, veuillez réessayer plus tard","Erreur");
    }
    )
  }

  trustUrl(url:string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

export interface Colis {
  "@context"?: string;
  "@id"?:      string;
  "@type"?:    string;
  id?:         number;
  reference?:  string;
  prix?:       number;
  statut?:     string;
  mode?:       string;
  villeExp?:   string;
  villeRec?:   string;
  message?:    string;
}
